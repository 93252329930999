'use strict';

/**
 * @ngdoc overview
 * @name scrapQcrmApp
 * @description
 * # scrapQcrmApp
 *
 * Main module of the application.
 */
agGrid.LicenseManager.setLicenseKey("For_Trialing_ag-Grid_Only-Not_For_Real_Development_Or_Production_Projects-Valid_Until-14_February_2021_[v2]_MTYxMzI2MDgwMDAwMA==0f4ca9c520ebff100f2c356273b1b155");
agGrid.initialiseAgGridWithAngular1(angular);

angular.module('scrapQcrmApp', [
  "xeditable",
  'ngAnimate',
  'ngCookies',
  'ngResource',
  'ngRoute',
  'ui.select',
  'ngSanitize',
  'ngTouch',
  'ui.router',
  'angular-loading-bar',
  'LocalStorageModule',
  'environment',
  'ui.bootstrap',
  'angular-growl',
  'base64',
  'geolocation',
  'ngFileUpload',
  'ngMessages',
  'checklist-model',
  'ngTable',
  'angular-momentjs',
  'ngMap',
  'ui.calendar',
  'daterangepicker',
  'datatables',
  "chart.js",
  "ngImgCrop",
  "ngCrossfilter",
  "ngPlaceholderShimmer",
  "fCsv",
  "as.sortable",
  "ngToast",
  "ui.bootstrap.datetimepicker",
  "moment-picker",
  "btorfs.multiselect",
  "starRatingDirective", ,
  "scrollToEnd",
  'agGrid',
  'angularjs-dropdown-multiselect'
  // 'ui.bootstrap.alert',
  // 'ui.bootstrap.dialog',
  // 'ui.bootstrap.tabs'
])
  .config(function ($stateProvider, $urlRouterProvider, growlProvider, $sceProvider, envServiceProvider, cfpLoadingBarProvider, localStorageServiceProvider) {
    cfpLoadingBarProvider.includeSpinner = true;
    $sceProvider.enabled(true);

    localStorageServiceProvider.setPrefix('scrapQApp')
      .setStorageType('localStorage')
      .setNotify(true, true);
    growlProvider.globalTimeToLive(2000);
    // console.log("Angular initialized");
    var restApi = {
      dashboard: "/api/dashboard/statistics?city=:city",
      addCustomPrice: "/api/customprice/add",
      editCustomPrice: "/api/customprice/update",
      deleteCustomPrice: "/api/customprice/delete",
      pendingOtps: "/api/registrations",
      pendingRequests: "/api/sells",
      allRequests: "/api/dashboard/requests/all",
      productsWithCity: "/api/categories-city/:city",
      products: "/api/categories",
      productsForCommunitySell: "/api/categories/community",
      uploadFile: "/filemanager/uploadFile",
      addProducts: "/api/category",
      editProduct: "/api/category/:id",
      addUserPriceCapsule: "/api/price-capsule/:userType/add",
      deleteProductPricing: "/api/price-capsule/delete/:aid",
      markDefaultPriceCapsule: "/api/price-capsule/mark-default/:userType/:aid",
      retrieveCategory: "/api/category/:categoryId",
      retrieveConsumerCategoriesForAUser: "/api/get/consumercategories/:userId",
      complaints: "/api/complaints",
      complaintRemark: "/api/complaint/remarks",
      updateProducts: "/api/updatecategory",
      priceUpdate: "/api/category/price",
      login: "/api/auth/login",
      logout: "/api/auth/logout",
      getConsumer: "/api/user/:userType/search/:text?city=:city",
      // "getAgent": "/api/registration",
      getAgent: "/api/user/:userType/search/:text?city=:city",
      getWholesaler: "/api/user/:userType/search/:text?city=:city",
      addAgentDocument: "/api/agent/document",
      addWholesalerDocument: "/api/wholesaler/document",
      addVendorDocument: "/api/vendor/document",
      getPricing: "/api/price/:role/:city",
      getPricingCapsule: "/api/price-capsule/:role/:city",
      addPricing: "/api/price/add",
      getAgentProfile: "/api/agent/profile/:aid",
      getWholesalerProfile: "/api/wholesaler/profile/:aid",
      getConsumerProfile: "/api/consumer/profile/:aid",
      getProspectProfile: "/api/prospect/profile/:aid",
      updateProspectStatus: "/api/prospect/updatestatus",
      getSellRquests: "/api/sells",
      addPaymentInfo: "/api/wholesaler/addPaymentInfo",
      getSellById: "/api/sell/:id",
      payToConfirmationId: "/api/payment/:id/:paymentType",
      retrievePayment: "/api/payment/retrieve/:id",
      getBidById: "/api/bid/:id",
      signup: "/api/registration/crm/user",
      saveAddress: "/api/address",
      updateConsumerRegistration: "/api/update/registration/crm/consumer",
      updateAddress: "/api/updateaddress",
      getImageFileById: "/fileManager/getImageFileById",
      getFileById: "/fileManager/getFileById/:id",
      sellNowPickup: "/api/sell/pickup",
      sellRequestForCommunityAppointment: "/api/community-appointment/sell/pickup",
      sellNowDrop: "/api/sell/drop",
      getSlots: "/api/sell/slots/available",
      getAddress: "/api/addresses",
      cancelSellRquests: "/api/sell/:cid/cancel",
      cancelSellRquestsRecurring: "/api/sell/:cid/cancelRecurring",
      requestSellPayment: "/api/sell/pay/crm",
      retrieveSellPayment: "/api/sell/:sellId/pay/status/:transactionId",
      sendTransactionCode: "/api/send/transaction-code/:sellId",
      verifyTransactionCode: "/api/verify/transaction-code/:sellId/:transactionCode/:password",
      getAgentsByLatLngAndSellId:
        "/api/addresses/lng/:lng/lat/:lat/agents/:sellId",
      getAgentsByLatAndLng: "/api/addresses/lng/:lng/lat/:lat/agents",
      getBids: "/api/bids",
      placeBid: "/api/bid",
      userCategories: "/api/consumer/categories",
      updateProfile: "/api/:id/profile",
      setDefault: "/api/address/:id/default",
      complaintsCategory: "/api/complaint/categories",
      complaintsType: "/api/complaint/:cid/types",
      saveComplaints: "/api/complaint",
      pendingAgents: "/api/registrations?userType=SQAGENT",
      searchConsumerRegistration: "/api/registrations?userType=CONSUMER&verifyConsumer=true&mobileNo=:mobileNo",
      usersLoad: "/api/registrations?userType=:userType&city=:city",
      getCustomersAgGrid: "/api/registrations/customers/ag-grid",
      getAppointmentsGrid: "/api/sells/ag-grid",
      getCommunityAppointmentsGrid: "/api/community-appointments/ag-grid",
      getCommunityCustomers: "/api/community/customers/:communityId",
      getCommunityStatusGraphs: "/api/community/graphs/status/:communityId",
      getCommunityProductsGraphs: "/api/community/graphs/products/:communityId",
      getIncompleteCustomers: "/api/registrations/customers/invalid",
      completeInvalidRegistration: "/api/registration/crm/complete-invalid-registration",
      allUsersLoad: "/api/registrations?city=:city",
      usersLoadBucket:
        "/api/registrations?userType=:userType&userBucketType=:userBucketType&city=:city",
      agentsLoadForWholesaler:
        "/api/registrations/wholesaler/:wholesalerId/agents",
      completedAppointmentsForWholesaler: "/api/sells",
      retrieveRegistrationsByCategories: "/api/registrations/user/categories",
      retrieveMasterFranchise: "/api/registrations/masterFranchise",
      saveTransportInventory: "/api/warehouse/transportInventory",
      getTransportInventoriesForAWarehouse: "/api/warehouse/transportInventory",
      retrieveAllInventories: "/api/warehouse/retrieveAllInventories",
      getBalanceInventoryForWarehouse:
        "/api/warehouse/inventories/balancesheet",
      assignAgent: "/api/sell/assign",
      checkAgentSlotAvailability: "/api/agent/Slots/:agentId/:sellObjId",
      unavilable: "/api/agent/TODAY/:status/count",
      sellCount: "/api/sell/:date/counts?city=:city",
      rejectAgent: "/api/sell/:id/reject",
      refferalCode: "/api/registrations/referred/:regId",
      deleteAddress: "/api/address/:id/deleteaddress",
      updatePickup: "/api/sell/:sellId/pickup",
      updateDrop: "/api/sell/:sellId/drop",
      getRatings: "/api/feedback/ratings",
      getPendingFeedBacks: "/api/feedbacks?consumerid=:cid&status=pending",
      getRatingTags: "/api/feedback/rating/:id",
      submitFeedBack: "/api/feedback",
      skipFeedBack: "/api/feedback/:id/skip",
      addComment: "/api/sell/comment",
      disableUser: "/api/registration/disable",
      disableWarehouse: "/api/warehouse/disable",
      getComments: "/api/sell/{sellId}/comments",
      updateSellItems: "/api/sell/items",
      completeSellItems: "/api/sell/complete",
      declineRequest: "/api/sell/decline",
      reschedule: "/api/sell/reschedule",
      getCalendar: "/api/sell/calendar/from/:from/to/:to?city=:city",
      getProspectCounts: "/api/prospect/counts",
      getAllCity: "/api/city/getAll",
      inventory: "/api/agent/:agentId/inventory/from/:fromDate/to/:toDate",
      wholesalerInventory:
        "/api/wholesaler/:wholesalerId/inventory/from/:fromDate/to/:toDate",
      activitiesLog: "/api/sell/activity/from/:fromDate/to/:toDate/:city",
      getAgentLocation:
        "/api/agent/track?agentId=:agentId&fromDate=:fromDate&toDate=:toDate&city=:city",
      getWholesalerLocation:
        "/api/agent/track?wholesalerId=:wholesalerId&city=:city",
      getWholesalerPayments: "/api/wholesaler/paymentInfo/:wholesalerId",
      addWarehouses: "/api/warehouse",
      getWarehouse: "/api/warehouses?city=:city",
      warehouseLoad: "/api/warehouses?city=:city",
      getWarehouseProfile: "/api/warehouse/:id",
      updateWarehouse: "/api/warehouse/:id",
      addVendor: "/api/vendor",
      updateVendor: "/api/vendor/:vendorId",
      disableVendor: "/api/vendor/disable",
      enableVendor: "/api/vendor/enable",
      vendorLoad: "/api/vendors?city=:city",
      getVendorProfile: "/api/vendor/:id",
      getVendor: "/api/vendors?city=:city",
      retrieveAllVendorsCity: "/api/vendors-city/:city",
      getAllVendorsDetails: "/api/vendors/get-all-details?city=:city",
      // loadConsumer: "https://sales-dev.scrapq.com/api/search/user",
      // updateConsumer: "https://sales-dev.scrapq.com/api/approved-user",
      // consumerDetails: "https://sales-dev.scrapq.com/api/user-details",
      // loadConsumer: "https://sales-dev.scrapq.com/api/search/user",
      loadConsumer: "/api/search/user",
      loadLead: "/api/search/lead", //main api to get records based on lead status
      // loadLead:"/api/search/user", //api to get all the user records without lead status, limit 50, skip 0
      updateLead: "/api/update-lead", // api to update sales lead.
      unAssignLead: "/api/unassign-lead",
      assignSalesToLeads: "/api/assign-sales-to-leads", //apit to update only sales person to leads (single or multiple)
      // loadLead:"/api/add-user",
      addLead: "/api/add-lead",
      deleteLead: "/api/delete-lead", // api to Delete sales lead.
      updateConsumer: "/api/approved-user", //api to change the status to Approved
      consumerDetails: "/api/user-details",//trying with this now
      operatorDetails: "/api/operator-details/:userId",
      getSalesAgentProfile: "/api/salesagent/profile/:id",
      getSalesAgent: "/api/user/:userType/search/:text?city=:city",
      addSalesAgent: "/api/add/salesagent",
      editSalesAgent: "/api/edit/salesagent",
      getAllSalesAgents: "/api/get-all/salesagents/:city/:enabled",
      disableSalesAgent: "/api/disable",
      getSalesPerson: "/api/get-sales-person", //api to get list of Sales Persons from Sales db
      retrieveSalesReports: "/api/reports/GTV",
      loadStatistic: "/api/operator-statistic",
      getUserPriceCapsules: "/api/price-capsule/:userType/:city",
      loadBooking: "/api/logistics/lynk/bookings",
      addLynkBooking: "/api/logistics/lynk/booktrip",
      getLogisticsBookingDetails: "/api/logistics/lynk/gettripdata/:id",
      addWarehouse: "/api/warehouse",
      getTrackingData: "/api/logistics/lynk/gettrackingdata/:id",
      getBooking: "/api/logistics/lynk/get/booking/:id",
      tomorrowAgentAppointments:
        "/api/sells?page=-1&status=pending&agentid=:agentId&fromdate=:fromDate&todate=:toDate",
      cancelBooking: "/api/logistics/lynk/cancelbooking/:orderNo/:reasonCode",
      getAllPayments: "/api/get/payments",
      getPayment: "/api/payment/retrieve/:id",
      addWallet: "/api/wallet",
      getWallets: "/api/get/wallets",
      getMessages: "/api/sms/retrieve/:senderMobile",
      sellOrder: "/api/sell/editOrder",
      getWarehouseInventory: "/api/warehouse-inventory",
      addInventory: "/api/warehouse/incomingInventory/:warehouseId",
      retrieveTotalInventory:
        "/api/warehouse/get-expected-inventory/:warehouseId/from/:fromDate/to/:toDate/:city",
      retrieveActualInventory:
        "/api/warehouse/get-actual-inventory/:warehouseId/from/:fromDate/to/:toDate/:city",
      retrieveAgentItems:
        "/api/warehouse/agentInventory/:warehouseId/from/:fromDate/to/:toDate/:city",
      distinctCategories: "/api/categories/distinct/:city",
      getSaleOrderForWarehouse: "/api/get-sale-orders/:id/:vendorId/:fromDate/:toDate/:city",
      getSaleOrderGrid:"/api/saleorder/ag-grid/ssrm",
      getSaleOrderDetails:"/api/get-sale-order/:id",
      getPendingSaleOrdersForAVendor: "/api/get-pending-sale-orders/:vendorId/:city",
      acceptVendorSaleOrder: "/api/sale-order/accept/:id",
      rejectVendorSaleOrder: "/api/sale-order/reject/:id",
      retrieveItemsForVendor: "/api/vendor-items/modal/:id",
      updateDetailsForVendorItemsModal: "/api/vendor-items/update-modal/:id",
      createSaleOrder: "/api/sale-order/vendor",
      addVendorItems: "/api/addvendoritems",
      addVendorPricing: "/api/add-vendor-pricing",
      updateVendorPricing: "/api/vendor-price-update",
      deleteVendorPricing: "/api/vendor-price-delete",
      retrievePricingForVendor: "/api/vendor/vendor-price/:id",
      retrievePricingForVendorModal: "/api/vendor-price/modal/:id",
      validateClearance: "/api/inventory-reports/warehouse-inventory/validate-clearance-items",
      createClearanceSaleOrder: "/api/inventory-reports/warehouse-inventory/create-clearance-sale-order",
      dispatchVendorSaleOrder: "/api/sale-order/dispatch/:id/:billNo/:truckNo",
      retrieveAllItems: "/api/vendor/all/vendor-items/:city",
      retrieveAllPricing: "/api/vendor/all/vendor-price/:city",
      searchSellPhotosRequest: "/api/sells/photos",
      getVendorSaleOrder: "/api/sale-order/vendor/:id",
      retrieveCustomPriceForConsumer: "/api/customprice/get/:id",
      retrieveCustomPrice: "/api/customprice/retrieve",
      addHoliday: "/api/add-holiday",
      addAreaHoliday: "/api/area-holidays/add",
      getHoliday: "/api/get-city-holidays",
      getAreaHoliday: "/api/get-area-holidays",
      getAreaHolidaysForConsumer: "/api/area-holidays/:addressId",
      getAreaHolidayForEdit: "/api/get-area-holiday",
      deleteHoliday: "/api/delete-holiday/:recurringId/:date/:city",
      deleteAreaHoliday: "/api/delete-area-holiday/:recurringId/:date/:areaId",
      addSetting: "/api/platform-settings/add",
      getAllSettings: "/api/platform-settings/get-all/:city",
      updateSetting: "/api/platform-settings/update",
      getEnabledSettings: "/api/platform-settings/get-toggle-on/:city",
      changeAppointmentDateAndSendSMS: "/api/holiday/appointments/date/sms",
      twalletOtp: "/api/payment/t-wallet/otp/:id",
      twalletOtpPayment: "/api/payment/t-wallet/otp-payment/:id/:otp",
      getAllApartments: "/api/apartments",
      addApartment: "/api/add/apartment",
      getApartmentById: "/api/get/apartment/:id",
      getAddressesByUserType: "/api/get-addresses/type",
      editApartment: "/api/edit/apartment",
      updateApartment: "/api/update/apartment",
      deleteApartment: "/api/delete/apartment",
      deleteBlock: "/api/disable/community/block",
      createCommunityAppointment: "/api/create-community-appointment",
      retrieveCommunityAppointmentById: "/api/retrieve/community-appointment/:id",
      retrieveSellsForAppointment: "/api/community-appointment/sells/:id",
      retrieveItemsForAppointment: "/api/community-appointment/items/:id",
      searchApartments: "/api/apartment/search/:text/:city",
      retrieveApartmentAppointments: "/api/retrieve/apartmentAppointment/:id",
      cancelAppointment: "/api/community-appointment/:id/cancel",
      completeAppointment: "/api/community-appointment/complete",
      assignApointmentToAgent: "/api/community-appointment/assign/agent",
      updateAppointment: "/api/community-appointment/:id/update",
      agentAppointmentsPerSlot: "/api/agent/appointments/:date/:city",
      getAgentAttendance: "/api/agent/attendance/:agentId",
      slotsCheckCreateAppointment: "/api/sell/slots/check",
      retrieveInventoryCountforWarehouse:
        "/api/warehouse/get-inventory/count/:id/from/:fromdate/to/:toDate/:city",
      getCountryDetails: "/api/country/getDetails/:country",
      retrieveAgentSellDetails: "/api/agent/sellDetails/:id",
      consumerSellsDetails: "/api/consumers-sell-dashboard/:id",
      addArea: "/api/add/area",
      getArea: "/api/get/area/:city",
      editArea: "/api/edit/area",
      deleteArea: "/api/delete/area/:areaId",
      slotsCheck: "/api/sell/slots/:sellId",
      getConsumersData: "/api/registration/data",
      installStatistic:
        "https://pjproxy.in/http://dev.scrapq.com:8081/appstats/installs/installs_com.scrapq.consumer_PARAM_overview.csv",
      addAgentComment: "/api/sell/agentComment", //save agent comment
      getWarehouseInventoryReports: "/api/inventory-reports/warehouse-inventory?city=:city",
      getProductInventoryReports: "/api/inventory-reports/product-inventory?city=:city",
      validateClearanceWarehouses: "/api/inventory-reports/product-inventory/validate-clearance-items",
      createClearanceSaleOrderWarehouses: "/api/inventory-reports/product-inventory/create-clearance-sale-order",
      getProductCategories: "/api/categories/distinct-product-categories/:city",
      getProductCategoriesWithIds: "/api/categories/distinct-product-categories/:city?includeIds=true",
      getLocations: "https://mapproxy.scrapq.com/maps/api/place/autocomplete/json?input=",
      getLatLng: "https://mapproxy.scrapq.com/maps/api/place/details/json?placeid=",
      getOrgChart: "/api/payout/users?city=:city&recursive=:bool&ownerId=:id",
      getAccountsPayableDetails: "/api/payout/accounts-payable?city=:city",
      getAccountsReceivableDetails: "/api/payout/accounts-receivable?city=:city",
      getViewAccountsPayableModalDetails: "/api/payout/view-accounts-payable-modal?city=:city&invoiceId=:invoiceId",
      payInvoiceAmount: "/api/payout/invoice-payment",
      getPayoutHistory: "/api/payout/payout-history?city=:city&agentId=:agentId&toDate=:date",
      getPayoutRecordById: "/api/payout/payout-record?city=:city&payoutId=:payoutId",
      getTransportInventories: "/api/warehouse/get-transport-inventories/:fromWarehouseId/:toWarehouseId/:fromDate/:toDate/:city",
      rejectTransferRequest: "/api/transport-inventory/reject/:confirmationId",
      acceptTransferRequest: "/api/transport-inventory/accept/:confirmationId",
      dispatchTransferRequest: "/api/transport-inventory/dispatch/:confirmationId/:billNo/:truckNo",
      getImageFileById: "/fileManager/getImageFileById",
      getImageFileFromSales: "/images",
      retrieveDocuments: "/api/documents?city=:city&userType=:userType",
      uploadConsumerDocuments: "/api/upload/consumer/documents",
      disableConsumerDocuments: "/api/disable/consumer/documents/:id",
      retrieveConsumerDocuments: "/api/retrieve/consumer/documents/:userId",
      retriveCommunityCatogories: "/api/community/categories/:city",
      addBlocks: "/api/add/community/block",
      updateBlocks: "/api/update/community/block",
      getBlocks: "/api/retrieve/blocks/:id",
      getCommunityAppointments: "/api/retrieve/community-appointments/:id",
      getAppointmentWiseReports: "/api/reports/purchase/appointments-wise",
      getGroupWiseReports: "/api/reports/purchase/group-wise",
      getAgentWiseReports: "/api/reports/purchase/agents-wise",
      getAgentsAttendance: "/api/reports/purchase/agents-attendance",
      getPaymentStatusReport: "/api/reports/payments/status",
      getPaymentFraudCheckReport: "/api/reports/payments/fraud/check",
      getCelektAppointmentWiseReport: "/api/reports/celekt/purchase/appointments-wise",
      getCelektRRRWiseReport: "/api/reports/celekt/purchase/rrr-wise",
      getCelektProductWiseReport: "/api/reports/celekt/purchase/product-wise",
      getCelektRRRWiseSubGridReport: "/api/reports/celekt/purchase/rrr-wise-sub-grid",
      getCelektProductWiseSubGridReport: "/api/reports/celekt/purchase/product-wise-sub-grid",
      getWarehouseWiseReports: "/api/reports/purchase/warehouse-wise",
      getConsolidatedProductWiseReports: "/api/reports/products-wise/consolidated",
      geProductWiseSalesReports: "/api/reports/products-wise/sale",
      getProductWisePurchaseReports: "/api/reports/products-wise/purchase",
      getVendorWiseReports: "/api/reports/sales/vendor-wise",
      getConsumerAddressTypeWiseReports: "/api/reports/purchase/customer-address-type-wise",
      getInventoryReports: "/api/reports/purchase/inventory",
      getSaleOrderReports: "/api/reports/sales/sale-order",
      getSalesAccountingReports: "/api/reports/sales/accounting",
      getDailySummaryReport: "/api/reports/daily-summary",
      getCustomerGraphData: "/api/reports/graphs/customers",
      getSellsGraphData: "/api/reports/graphs/sells",
      getSellsGraphData: "/api/reports/graphs/sells",
      retrieveDocumentsList: "/api/documents?city=:city&userType=:userType",
      uploadUserDocuments: "/api/upload/registrations/documents",
      getUserDocuments: "/api/retrieve/registrations/documents/:userId",
      disableUserDocuments: "/api/disable/registrations/documents/:documentId",
      getUserRoleMetrix: "/api/user-role-matrix",
      updateUserRoleMetrix: "/api/user-role-matrix/update",
      getUserRoleModules: "/api/retrieve/modules/fields?module=:module&subModule=:subModule&type=:type",

    };
    /* environment start*/
    envServiceProvider.config({
      vars: {
        local: {
          apiUrl: 'http://localhost:8080/scrapq-restcontroller',
          restApi: restApi,
          salesApiUrl: 'http://localhost:8083',
        },
        development: {
          apiUrl: "https://devapi.scrapq.com/scrapq-restcontroller",
          staticUrl: "http://localhost:9000",
          restApi: restApi,
          salesApiUrl: 'https://sales-dev.scrapq.com',
        },
        production: {
          apiUrl: "https://prodapi.scrapq.com/scrapq-restcontroller",
          staticUrl: "http://localhost:8080/scrapq-restcontroller",
          restApi: restApi,
          salesApiUrl: 'https://sales.scrapq.com',
        },
      },
    });

    envServiceProvider.check();



    // envServiceProvider.set("local");
    //  envServiceProvider.set("development");
    envServiceProvider.set("production");

    /* environment end*/

    $urlRouterProvider.otherwise("dashboard");
    $stateProvider
      .state("root", {
        url: "",
        views: {
          "content@": {
            controller: function ($state) {
              $state.go("root.dashboard");
            },
          },
          header: {
            templateUrl: "views/header.html",
            controller: "AboutCtrl",
          },
          "header-aside": {
            templateUrl: "views/header-aside.html",
            controller: "HeaderCtrl",
          },
          footer: {
            templateUrl: "views/footer.html",
            // controller:"HeaderCtrl"
          },
        },
      })
      .state("root.dashboard", {
        url: "/dashboard",
        views: {
          "content@": {
            templateUrl: "views/dashboard.html",
            controller: "DashboardCtrl",
          },
        },
      })
      .state("login", {
        url: "/login",
        views: {
          "content@": {
            templateUrl: "views/login.html",
            controller: "LoginCtrl",
          },
        },
      })
      .state("root.add-user", {
        url: "/add-user/:id",
        views: {
          "content@": {
            templateUrl: "views/add-user.html",
            controller: "AddUserCtrl",
          },
        },
      })
      .state("root.pending-otps", {
        url: "/pending-registration",
        views: {
          "content@": {
            templateUrl: "views/pending-otps.html",
            controller: "PendingOtpsCtrl",
          },
        },
      })
      .state("root.pending-requests", {
        url: "/pending-requests",
        params: {
          fromdate: new Date(),
          todate: new Date(),
        },
        views: {
          "content@": {
            templateUrl: "views/pending-requests.html",
            controller: "PendingRequestsCtrl",
          },
        },
      })
      .state("root.route-planner", {
        url: "/route-planner",
        views: {
          "content@": {
            templateUrl: "views/routeplan.html",
            controller: "RouteMapCtrl",
          },
        },
      })
      .state("root.slot-optimisation", {
        url: "/slot-optimisation",
        views: {
          "content@": {
            templateUrl: "views/slotOptimisation.html",
            controller: "SlotOptimisationCtrl",
          },
        },
      })
      .state("root.consumer", {
        url: "/customers/:pageTitle",
        params: { type: null },
        views: {
          "content@": {
            templateUrl: "views/consumers.html",
            controller: "ConsumerCtrl",
          },
        },
      })
      .state("root.add-consumer", {
        url: "/add-consumer",
        params: { type: null },
        views: {
          "content@": {
            templateUrl: "views/add-consumer.html",
            controller: "AddCustomerCtrl",
          },
        },
      })
      .state("root.invalidCustomers", {
        url: "/invalid-customers",
        params: { type: null },
        views: {
          "content@": {
            templateUrl: "views/invalidCustomers.html",
            controller: "InvalidCustomersCtrl",
          },
        },
      })
      .state("root.reporting", {
        url: "/reporting",
        params: { type: null, from: null },
        views: {
          "content@": {
            templateUrl: "views/reporting.html",
            controller: "ReportingCtrl",
          },
        },
      })
      .state("root.aquisitions", {
        url: "/aquisitions",
        views: {
          "content@": {
            templateUrl: "views/aquisitions.html",
            controller: "AquisitionCtrl",
          },
        },
      })
      .state("root.sales-reports", {
        url: "/sales-reports",
        params: {
          fromdate: new Date(),
          todate: new Date(),
        },
        views: {
          "content@": {
            templateUrl: "views/aquisitionstats.html",
            controller: "AquisitionStatsCtrl",
          },
        },
      })
      .state("root.appointment-wise-reports", {
        url: "/appointment-wise-reports",
        params: {
          fromdate: new Date(),
          todate: new Date(),
        },
        views: {
          "content@": {
            templateUrl: "views/appointment-wise-reports.html",
            controller: "AppointmentWiseReportsController",
          },
        },
      })
      .state("root.group-wise-reports", {
        url: "/group-wise-reports/:fromdate/:todate",
        views: {
          "content@": {
            templateUrl: "views/group-wise-report.html",
            controller: "GroupWiseReportsController",
          },
        },
      })
      .state("root.agent-wise-reports", {
        url: "/agent-wise-reports",
        params: {
          fromdate: new Date(),
          todate: new Date(),
        },
        views: {
          "content@": {
            templateUrl: "views/agent-wise-report.html",
            controller: "AgentWiseReportsController",
          },
        },
      })
      .state("root.product-wise-reports", {
        url: "/product-wise-reports/:type",
        params: {
          fromdate: new Date(),
          todate: new Date(),
        },
        views: {
          "content@": {
            templateUrl: "views/product-wise-report.html",
            controller: "ProductWiseReportsController",
          },
        },
      })
      .state("root.vendor-wise-reports", {
        url: "/vendor-wise-reports/:type",
        params: {
          fromdate: new Date(),
          todate: new Date(),
        },
        views: {
          "content@": {
            templateUrl: "views/vendor-wise-report.html",
            controller: "VendorWiseReportController",
          },
        },
      })
      .state("root.daily-summary-report", {
        url: "/daily-summary-report",
        params: {
          fromdate: new Date(),
          todate: new Date(),
        },
        views: {
          "content@": {
            templateUrl: "views/daily-summary-report.html",
            controller: "DailySummaryReportController",
          },
        },
      })
      .state("root.warehouse-wise-reports", {
        url: "/warehouse-wise-reports",
        params: {
          fromdate: new Date(),
          todate: new Date(),
        },
        views: {
          "content@": {
            templateUrl: "views/warehouse-wise-reports.html",
            controller: "WareHouseWiseReportsController",
          },
        },
      })
      .state("root.customer-address-wise-reports", {
        url: "/customer-address-wise-reports",
        params: {
          fromdate: new Date(),
          todate: new Date(),
        },
        views: {
          "content@": {
            templateUrl: "views/customer-address-wise-reports.html",
            controller: "ConsumerAddressReportsController",
          },
        },
      })
      .state("root.inventory-reports", {
        url: "/inventory-reports",
        params: {
          fromdate: new Date(),
          todate: new Date(),
        },
        views: {
          "content@": {
            templateUrl: "views/inventory-report.html",
            controller: "InventoryReportsController",
          },
        },
      })
      .state("root.sale-order-reports", {
        url: "/sale-order-reports",
        params: {
          fromdate: new Date(),
          todate: new Date(),
        },
        views: {
          "content@": {
            templateUrl: "views/sale-order-report.html",
            controller: "SaleOrderReportsController",
          },
        },
      })
      .state("root.accounting-reports", {
        url: "/accounting-reports",
        params: {
          fromdate: new Date(),
          todate: new Date(),
        },
        views: {
          "content@": {
            templateUrl: "views/accounting-reports.html",
            controller: "AccountingReportsController",
          },
        },
      })
      .state("root.payment-status-reports", {
        url: "/payment-status-reports",
        params: {
          fromdate: new Date(),
          todate: new Date(),
        },
        views: {
          "content@": {
            templateUrl: "views/payment-status-reports.html",
            controller: "PaymentStatusReportsController",
          },
        },
      })
      .state("root.payment-fraud-reports", {
        url: "/payment-fraud-reports",
        params: {
          fromdate: new Date(),
          todate: new Date(),
        },
        views: {
          "content@": {
            templateUrl: "views/payment-fraud-reports.html",
            controller: "PaymentFraudReportsController",
          },
        },
      })
      .state("root.celekt-appointment-reports", {
        url: "/celekt-appointment-reports",
        params: {
          fromdate: new Date(),
          todate: new Date(),
        },
        views: {
          "content@": {
            templateUrl: "views/celekt-appointment-wise-report.html",
            controller: "CelektAppointmentWiseReportsController",
          },
        },
      })
      .state("root.celekt-product-reports", {
        url: "/celekt-product-reports",
        params: {
          fromdate: new Date(),
          todate: new Date(),
        },
        views: {
          "content@": {
            templateUrl: "views/celekt-product-wise-report.html",
            controller: "CelektProductWiseReportsController",
          },
        },
      })
      .state("root.celekt-rrr-reports", {
        url: "/celekt-rrr-reports",
        params: {
          fromdate: new Date(),
          todate: new Date(),
        },
        views: {
          "content@": {
            templateUrl: "views/celekt-rrr-report.html",
            controller: "RRRReportsController",
          },
        },
      })
      .state("root.agents", {
        url: "/agents/:priceCapsuleId?",
        params: { priceCapsuleId: null, agentName: null },
        views: {
          "content@": {
            templateUrl: "views/agents.html",
            controller: "AgentsCtrl",
          },
        },
      })
      .state("root.wholesalers", {
        url: "/wholesalers/:priceCapsuleId?",
        params: { priceCapsuleId: null, wholesalerName: null },
        views: {
          "content@": {
            templateUrl: "views/wholesalers.html",
            controller: "WholesalersCtrl",
          },
        },
      })
      .state("root.wholesaleraccount", {
        url: "/accountws",
        views: {
          "content@": {
            templateUrl: "views/wholesalers.html",
            controller: "WholesalersCtrl",
          },
        },
      })
      .state("root.unassigned-lead", {
        url: "/unassigned-lead",
        views: {
          "content@": {
            templateUrl: "views/unassigned-lead.html",
            controller: "UnassignedLeadCtrl",
          },
        },
      })
      .state("root.assigned-lead", {
        url: "/assigned-lead",
        views: {
          "content@": {
            templateUrl: "views/assigned-lead.html",
            controller: "AssignedLeadCtrl",
          },
        },
      })
      .state("root.add-reo", {
        url: "/add-reo/:type",
        params: { type: null, fromPage: null },
        views: {
          "content@": {
            templateUrl: "views/add-user.html",
            controller: "AddUserCtrl",
          },
        },
      })
      .state("root.edit-warehouse", {
        url: "/edit-warehouse/:id",
        views: {
          "content@": {
            templateUrl: "views/warehouse-edit-profile.html",
            controller: "WarehouseEditProfileCtrl",
          },
        },
      })

      .state("root.follow-up", {
        url: "/follow-up",
        views: {
          "content@": {
            templateUrl: "views/follow-up.html",
            controller: "FollowUpCtrl",
          },
        },
      })
      .state("root.add-lead", {
        url: "/add-lead/:id",
        views: {
          "content@": {
            templateUrl: "views/add-lead.html",
            controller: "AddLeadCtrl",
          },
        },
      })
      .state("root.salesagents", {
        url: "/salesagents",
        views: {
          "content@": {
            templateUrl: "views/salesagents.html",
            controller: "SalesAgentsCtrl",
          },
        },
      })
      .state("root.pricing", {
        url: "/pricing",
        views: {
          "content@": {
            templateUrl: "views/pricing.html",
            controller: "PricingCtrl",
          },
        },
      })
      .state("root.payments", {
        url: "/payments",
        views: {
          "content@": {
            templateUrl: "views/payments.html",
            controller: "PaymentsCtrl",
          },
        },
      })
      .state("root.accounts-payables", {
        url: "/accounts-payables",
        params: { selectedDate: null },
        views: {
          "content@": {
            templateUrl: "views/accounts-payables.html",
            controller: "AccountsPayableCtrl",
          },
        },
      })
      .state("root.accounts-receivables", {
        url: "/accounts-receivables",
        params: { selectedDate: null },
        views: {
          "content@": {
            templateUrl: "views/accounts-receivables.html",
            controller: "AccountsReceivableCtrl",
          },
        },
      })
      .state("root.wallets", {
        url: "/wallets",
        views: {
          "content@": {
            templateUrl: "views/wallets.html",
            controller: "WalletsCtrl",
          },
        },
      })
      .state("root.add-pricing", {
        url: "/add-pricing",
        views: {
          "content@": {
            templateUrl: "views/add-pricing.html",
            controller: "AddPricingCtrl",
          },
        },
      })
      .state("root.custompricing", {
        url: "/custompricing",
        views: {
          "content@": {
            templateUrl: "views/custompricing.html",
            controller: "CustomPricingCtrl",
          },
        },
      })
      .state("root.customPricing", {
        url: "/customer/custompricing",
        params: { items: null },
        views: {
          "content@": {
            templateUrl: "views/add-custom-pricing.html",
            controller: "AddCustomPricingCtrl",
          },
        },
      })
      .state("root.reopricing", {
        url: "/reopricing",
        views: {
          "content@": {
            templateUrl: "views/reo-pricing.html",
            controller: "ReoPricingCtrl",
          },
        },
      })
      .state("root.skupricing", {
        url: "/skupricing",
        views: {
          "content@": {
            templateUrl: "views/sku-pricing.html",
            controller: "skuPricingCtrl",
          },
        },
      })
      .state("root.agents-appiontments", {
        url: "/agents-appiontments",
        views: {
          "content@": {
            templateUrl: "views/agents-appiontments.html",
            controller: "AgentsAppiontmentsCtrl",
          },
        },
      })
      .state("root.bids", {
        url: "/bids",
        views: {
          "content@": {
            templateUrl: "views/bids.html",
            controller: "BidsCtrl",
          },
        },
      })
      .state("root.products", {
        url: "/products/:type",
        params: { type: null },
        views: {
          "content@": {
            templateUrl: "views/products.html",
            controller: "ProductsCtrl",
          },
        },
      })
      .state("root.add-product", {
        url: "/add-product",
        views: {
          "content@": {
            templateUrl: "views/add-product.html",
            controller: "AddProductCtrl",
          },
        },
      })
      .state("root.edit-product", {
        url: "/edit-product/:id",
        views: {
          "content@": {
            templateUrl: "views/edit-product.html",
            controller: "EditProductCtrl",
          },
        },
      })
      .state("root.complaints", {
        url: "/complaints",
        views: {
          "content@": {
            templateUrl: "views/complaints.html",
            controller: "ComplaintsCtrl",
          },
        },
      })
      .state("root.sellsconsumer", {
        url: "/sellconsumer",
        views: {
          "content@": {
            templateUrl: "views/sells-consumers.html",
            controller: "SellConsumerCtrl",
          },
        },
      })
      .state("root.tablerow", {
        url: "/tablerow",
        views: {
          "content@": {
            templateUrl: "views/about.html",
            controller: "AboutCtrl",
          },
        },
      })
      .state("root.unassigned", {
        url: "/unassigned",
        params: {
          fromdate: new Date(),
          todate: new Date(),
        },
        views: {
          "content@": {
            templateUrl: "views/un-assigned.html",
            controller: "UnAssignedCtrl",
          },
        },
      })
      .state("root.completed", {
        url: "/completed",
        params: {
          fromdate: new Date(),
          todate: new Date(),
        },
        views: {
          "content@": {
            templateUrl: "views/completed-requests.html",
            controller: "CompletedCtrl",
          },
        },
      })
      .state("root.cancelled", {
        url: "/cancelled",
        params: {
          fromdate: new Date(),
          todate: new Date(),
        },
        views: {
          "content@": {
            templateUrl: "views/canceled-requests.html",
            controller: "CancelledCtrl",
          },
        },
      })
      .state("root.reports", {
        url: "/reports",
        views: {
          "content@": {
            templateUrl: "views/reports.html",
            controller: "ReportsController",
          },
        },
      })
      .state("root.declined", {
        url: "/declined",
        params: {
          fromdate: new Date(),
          todate: new Date(),
        },
        views: {
          "content@": {
            templateUrl: "views/declined-requests.html",
            controller: "DeclinedCtrl",
          },
        },
      })
      .state("root.rejected", {
        url: "/rejected",
        params: {
          fromdate: new Date(),
          todate: new Date(),
        },
        views: {
          "content@": {
            templateUrl: "views/rejected-requests.html",
            controller: "RejectedCtrl",
          },
        },
      })
      .state("root.closed", {
        url: "/closed",
        params: {
          fromdate: new Date(),
          todate: new Date(),
        },
        views: {
          "content@": {
            templateUrl: "views/closed-requests.html",
            controller: "ClosedCtrl",
          },
        },
      })
      .state("root.unattended", {
        url: "/unattended",
        params: {
          fromdate: new Date(),
          todate: new Date(),
        },
        views: {
          "content@": {
            templateUrl: "views/unattended-requests.html",
            controller: "UnattendedCtrl",
          },
        },
      })
      .state("root.user-role-metrics", {
        url: "/user-role-matrix",
        views: {
          "content@": {
            templateUrl: "views/user-role-metrics.html",
            controller: "UserRoleMetrixController",
          },
        },
      })
      .state("root.appointments", {
        url: "/appointments",
        params: {
          fromdate: new Date(),
          todate: new Date(),
        },
        views: {
          "content@": {
            templateUrl: "views/appointments.html",
            controller: "AppointmentCtrl",
          },
        },
      })
      .state("root.inventory", {
        url: "/inventory",
        views: {
          "content@": {
            templateUrl: "views/viewinventory.html",
            controller: "inventoryCtrl",
          },
        },
      })
      .state("root.productsInventory", {
        url: "/products-inventory",
        params: {
          index: null,
          page: null,
          sort: null,
          size: null,
          key: null,
          category: null,
        },
        views: {
          "content@": {
            templateUrl: "views/products-inventory.html",
            controller: "ProductsInventoryCtrl",
          },
        },
      })
      .state("root.inventoryList", {
        url: "/inventory-list",
        params: { index: null, page: null, sort: null, size: null, key: null },
        views: {
          "content@": {
            templateUrl: "views/inventory.html",
            controller: "InventoryListCtrl",
          },
        },
      })
      .state("root.agent-appointment-report", {
        url: "/agent-attendance-report",
        params: {
          index: null,
          page: null,
          sort: null,
          size: null,
          key: null,
          fromdate: new Date(),
          todate: new Date(),
        },
        views: {
          "content@": {
            templateUrl: "views/agent-appointment-report.html",
            controller: "AgentsAttendanceController",
          },
        },
      })
      .state("root.balanceSheet", {
        url: "/balance-sheet",
        params: {
          from: null,
          index: null,
          id: null,
          page: null,
          size: null,
          sort: null,
          key: null,
          item: null,
          category: null,
        },
        views: {
          "content@": {
            templateUrl: "views/balance-sheet.html",
            controller: "BalanceSheetCtrl",
          },
        },
      })
      .state("root.activities", {
        url: "/activities",
        views: {
          "content@": {
            templateUrl: "views/activities.html",
            controller: "activitiesCtrl",
          },
        },
      })
      .state("root.trackLocation", {
        url: "/trackLocation",
        views: {
          "content@": {
            templateUrl: "views/trackLocation.html",
            controller: "trackLocationCtrl",
          },
        },
      })
      .state("root.logistics", {
        url: "/logistics",
        views: {
          "content@": {
            templateUrl: "views/logistics.html",
            controller: "LogisticsCtrl",
          },
        },
      })
      .state("root.warehouses", {
        url: "/warehouses",
        views: {
          "content@": {
            templateUrl: "views/warehouses.html",
            controller: "WarehousesCtrl",
          },
        },
      })
      .state("root.vendor", {
        url: "/vendor",
        views: {
          "content@": {
            templateUrl: "views/vendor.html",
            controller: "VendorCtrl",
          },
        },
      })
      .state("root.add-vendor", {
        url: "/add-vendor/:type",
        params: { header: null, items: null },
        views: {
          "content@": {
            templateUrl: "views/add-vendor.html",
            controller: "AddVendorCtrl",
          },
        },
      })
      .state("root.edit-reo", {
        url: "/edit-reo/:id",
        views: {
          "content@": {
            templateUrl: "views/agent-edit-profile.html",
            controller: "AgentEditProfileCtrl",
          },
        },
      })
      .state("root.edit-partner", {
        url: "/edit-partner/:id",
        views: {
          "content@": {
            templateUrl: "views/wholesaler-edit-profile.html",
            controller: "WholesalerEditProfileCtrl",
          },
        },
      })
      .state("root.add-warehouse", {
        url: "/add-warehouse",
        views: {
          "content@": {
            templateUrl: "views/add-warehouse.html",
            controller: "AddWarehouseCtrl",
          },
        },
      })
      .state("root.edit-warehouse-profile", {
        url: "/edit-warehouse-profile.html",
        views: {
          "content@": {
            templateUrl: "views/edit-warehouse-profile.html",
            controller: "WarehouseEditProfileCtrl",
          },
        },
      })
      .state("root.incomingInventory", {
        url: "/incoming-inventory",
        views: {
          "content@": {
            templateUrl: "views/incoming-inventory.html",
            controller: "IncomingInventoryCtrl",
          },
        },
      })
      .state("root.inventoryConfirm", {
        url: "/inventory-confirm",
        views: {
          "content@": {
            templateUrl: "views/inventory-confirm.html",
            controller: "InventoryConfirmCtrl",
          },
        },
      })
      .state("root.transportInventory", {
        url: "/transport-inventory",
        params: { fromWarehouseId: null },
        // params:{initiateTransportInventoryItems: null,from:null,index:null,id:null,page:null,size:null,sort:null,key:null,item:null,category:null},
        views: {
          "content@": {
            templateUrl: "views/transport-inventory.html",
            controller: "TransportInventoryCtrl",
          },
        },
      })
      .state("root.balanceInventory", {
        url: "/balance-inventory",
        views: {
          "content@": {
            templateUrl: "views/balance-inventory.html",
            controller: "BalanceInventoryCtrl",
          },
        },
      })
      .state("root.saleOrder", {
        url: "/sale-order",
        params: { fromWarehouseId: null },
        views: {
          "content@": {
            templateUrl: "views/sale-order.html",
            controller: "SaleOrderCtrl",
          },
        },
      })
      .state("root.vendor-price", {
        url: "/vendor-price",
        views: {
          "content@": {
            templateUrl: "views/vendor-pricing.html",
            controller: "VendorPriceCtrl",
          },
        },
      })
      .state("root.vendor-items", {
        url: "/vendor-items",
        views: {
          "content@": {
            templateUrl: "views/vendor-items.html",
            controller: "VendorItemsCtrl",
          },
        },
      })
      .state("root.scrap-photos", {
        url: "/scrap-photos",
        views: {
          "content@": {
            templateUrl: "views/scrap-photos.html",
            controller: "ScrapPhotosCtrl",
          },
        },
      })
      .state("root.holidays", {
        url: "/holiday-calendar",
        views: {
          "content@": {
            templateUrl: "views/holidays.html",
            controller: "HolidayCtrl",
          },
        },
      })
      .state("root.settings", {
        url: "/platform-settings",
        views: {
          "content@": {
            templateUrl: "views/platform-settings.html",
            controller: "PlatformSettingsCtrl",
          },
        },
      })
      .state("root.add-platform-settings", {
        url: "/add-platform-settings",
        views: {
          "content@": {
            templateUrl: "views/add-platform-settings.html",
            controller: "AddPlatformSettingsCtrl",
          },
        },
      })
      .state("root.communities", {
        url: "/communities",
        params: { consumerId: null },
        views: {
          "content@": {
            templateUrl: "views/community.html",
            controller: "CommunityCtrl",
          },
        },
      })
      .state("root.add-apartment", {
        url: "/add-apartment",
        views: {
          "content@": {
            templateUrl: "views/add-apartment.html",
            controller: "AddApartmentCtrl",
          },
        },
      })
      .state("root.agent-slot-data", {
        url: "/agent-slot-data",
        views: {
          "content@": {
            templateUrl: "views/agent-slot-data.html",
            controller: "AgentSlotCtrl",
          },
        },
      })
      .state("root.area-list", {
        url: "/area-list",
        views: {
          "content@": {
            templateUrl: "views/area.html",
            controller: "AreaCtrl",
          },
        },
      })
      .state("root.sell-report", {
        url: "/sell-report",
        views: {
          "content@": {
            templateUrl: "views/sells-reporting.html",
            controller: "SellReportCtrl",
          },
        },
      })
      .state("root.org-chart", {
        url: "/org-chart",
        views: {
          "content@": {
            templateUrl: "views/org-chart.html",
            controller: "OrgChartCtrl",
          },
        },
      });
  })
  .run(function (
    $state,
    $global,
    $rootScope,
    $window,
    $stateParams,
    editableOptions
  ) {
    editableOptions.theme = "bs3";

    $rootScope.$on(
      "$stateChangeSuccess",
      function (event, currentState, previousState) {
        $window.scrollTo(0, 0);
      }
    );

    $rootScope.$on("$stateChangeStart", function (
      e,
      toState,
      toParams,
      fromState,
      fromParams
    ) {
      $rootScope.loadScript = function () {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src =
          "https://cdn.sobekrepository.org/includes/gmaps-markerwithlabel/1.9.1/gmaps-markerwithlabel-1.9.1.min.js";
        document.body.appendChild(script)
      };
      $rootScope.loadScript();
      var isLogin = toState.name === "login";
      $global.init();
      $rootScope.$emit("initMenu", "ok");
      if (isLogin) {
        $global.removeLocalItem("authentication");
        $global.removeLocalItem("sellReuestItems");
        $global.removeLocalItem("registration");
        $rootScope.$emit("initMenu", "ok");

      } else {
        var toStateName = toState.name;
        if (
          toStateName === "root.faq" ||
          toStateName === "root.about" ||
          toStateName === "root.otp" ||
          toStateName === "root.registration" ||
          toStateName === "root.forgotpassword"
        ) {
          return
        }
        if (
          $global.authentication == null ||
          $global.authentication === undefined ||
          $global.authentication === ""
        ) {
          e.preventDefault();
          $state.go("login");

        }
      }
    });



    $rootScope.hideAgGridLoadingRow = function (className) {
      let elements = [];
      if (className) {
        elements = document.getElementsByClassName(className);

      } else {
        elements = document.getElementsByClassName("ag-row-loading");
      }
      console.log(elements);
      if (elements[0]) {
        for (var i = 0; i < elements.length; i++) {
          elements[i].style.visibility = "hidden"; // or
          elements[i].style.display = "none"; // depending on what you're doing
        }
        elements[0].style.display = "none";
      }
    }

    // Network detecting
    $rootScope.online = navigator.onLine;
    if (!$rootScope.online) {
      var noInternet = angular.element(document.querySelector("#no-internet"));
      noInternet.removeClass("ng-hide");
      noInternet.css({
        display: "block",
        opacity: 1,
      })
      //console.log("Off line");
    }
    //console.log($rootScope.NotOnline);
    $window.addEventListener(
      "offline",
      function () {
        $rootScope.$apply(function () {
          var noInternet = angular.element(
            document.querySelector("#no-internet")
          );
          noInternet.removeClass("ng-hide");
          noInternet.css({
            display: "block",
            opacity: 1,
          });
          $rootScope.online = false
          //console.log("Off line");
        })
      },
      false
    );

    $window.addEventListener(
      "online",
      function () {
        $rootScope.$apply(function () {
          var noInternet = angular.element(
            document.querySelector("#no-internet")
          );
          noInternet.addClass("ng-hide");
          noInternet.css({
            display: "none",
            opacity: 0,
          });
          $rootScope.online = true;
          //Reload page again
          $state.go($state.current, $stateParams, {
            reload: true,
          })
          //console.log("Online");
        })
      },
      false
    )
  });
